import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Breadcrumbs from './components/Breadcrumbs';
import ReactGA from 'react-ga4';

// Initialize GA4
ReactGA.initialize('G-ZCSB466WGT');

// Utility function for event tracking
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label
  });
};

// Lazy load all page components
const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Services = React.lazy(() => import('./pages/Services'));
const Projects = React.lazy(() => import('./pages/Projects'));
const Contact = React.lazy(() => import('./pages/Contact'));

// Lazy load region pages
const PilbaraRegion = React.lazy(() => import('./pages/regions/Pilbara'));
const GoldfieldsRegion = React.lazy(() => import('./pages/regions/Goldfields'));
const SouthWestRegion = React.lazy(() => import('./pages/regions/SouthWest'));
const PeelRegion = React.lazy(() => import('./pages/regions/Peel'));
const PerthRegion = React.lazy(() => import('./pages/regions/Perth'));

// Lazy load service pages
const UAVSurveys = React.lazy(() => import('./pages/services/UAVSurveys'));
const ConstructionSurveys = React.lazy(() => import('./pages/services/ConstructionSurveys'));
const MiningSurveys = React.lazy(() => import('./pages/services/MiningSurveys'));
const LaserScanning = React.lazy(() => import('./pages/services/LaserScanning'));
const AsBuiltSurveys = React.lazy(() => import('./pages/services/AsBuiltSurveys'));
const EquipmentHire = React.lazy(() => import('./pages/services/EquipmentHire'));
const LandDevelopment = React.lazy(() => import('./pages/services/LandDevelopment'));
const EngineeringSurveys = React.lazy(() => import('./pages/services/EngineeringSurveys'));
const InfrastructureSurveys = React.lazy(() => import('./pages/services/InfrastructureSurveys'));
const MachineControl = React.lazy(() => import('./pages/services/MachineControl'));

// Lazy load project pages
const BussellHighway = React.lazy(() => import('./pages/projects/BussellHighway'));
const CloudbreakMine = React.lazy(() => import('./pages/projects/CloudbreakMine'));
const WitchcliffeEcoVillage = React.lazy(() => import('./pages/projects/WitchcliffeEcoVillage'));
const VasseDrain = React.lazy(() => import('./pages/projects/VasseDrain'));

function App() {
  return (
    <Router>
      <RouteTracker />
      <div className="min-h-screen bg-noble-white">
        <Navbar />
        <Breadcrumbs />
        <main>
          <Suspense fallback={<div className="min-h-screen flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-noble-navy"></div>
          </div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/uav-surveys" element={<UAVSurveys />} />
              <Route path="/services/construction-surveys" element={<ConstructionSurveys />} />
              <Route path="/services/mining-surveys" element={<MiningSurveys />} />
              <Route path="/services/laser-scanning" element={<LaserScanning />} />
              <Route path="/services/as-built-surveys" element={<AsBuiltSurveys />} />
              <Route path="/services/equipment-hire" element={<EquipmentHire />} />
              <Route path="/services/land-development" element={<LandDevelopment />} />
              <Route path="/services/engineering-surveys" element={<EngineeringSurveys />} />
              <Route path="/services/infrastructure-surveys" element={<InfrastructureSurveys />} />
              <Route path="/services/machine-control" element={<MachineControl />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/bussell-highway" element={<BussellHighway />} />
              <Route path="/projects/cloudbreak-mine" element={<CloudbreakMine />} />
              <Route path="/projects/witchcliffe-ecovillage" element={<WitchcliffeEcoVillage />} />
              <Route path="/projects/vasse-drain" element={<VasseDrain />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/regions/pilbara" element={<PilbaraRegion />} />
              <Route path="/regions/goldfields" element={<GoldfieldsRegion />} />
              <Route path="/regions/south-west" element={<SouthWestRegion />} />
              <Route path="/regions/peel" element={<PeelRegion />} />
              <Route path="/regions/perth" element={<PerthRegion />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

// Route tracking component
function RouteTracker() {
  const location = useLocation();
  
  useEffect(() => {
    // Enhanced page view tracking with search parameters
    ReactGA.send({ 
      hitType: "pageview", 
      page: location.pathname + location.search 
    });
  }, [location]);
  
  return null;
}

export default App;
