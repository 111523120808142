import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { trackEvent } from '../App';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About', href: '/about' },
  { name: 'Services', href: '/services' },
  { name: 'Projects', href: '/projects' },
  { name: 'Contact', href: '/contact' },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleNavClick = (itemName, isMobile = false) => {
    trackEvent('Navigation', 'Click', `${itemName}${isMobile ? ' (Mobile)' : ''}`);
  };

  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-noble-navy/95 backdrop-blur-sm">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link 
            to="/" 
            className="-m-1.5 p-1.5"
            onClick={() => handleNavClick('Logo')}
          >
            <span className="sr-only">Noble Surveys</span>
            <img
              className="h-12 w-auto"
              src={`${process.env.PUBLIC_URL}/images/NobleSurveys 6 800x200.png`}
              alt="Noble Surveys"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-noble-gray"
            onClick={() => {
              setMobileMenuOpen(true);
              trackEvent('Navigation', 'Open Mobile Menu', 'Mobile Menu Toggle');
            }}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-sm font-semibold leading-6 text-noble-white hover:text-noble-blue hover:bg-noble-white/90 hover:px-4 hover:py-2 hover:rounded-md transition-all duration-200"
              onClick={() => handleNavClick(item.name)}
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            to="/contact"
            className="bg-noble-white text-noble-navy px-6 py-3 rounded-lg font-semibold hover:bg-noble-blue hover:text-noble-white transition-all duration-300 ease-in-out"
            onClick={() => handleNavClick('Get Started')}
          >
            Get Started
          </Link>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => {
        setMobileMenuOpen(false);
        trackEvent('Navigation', 'Close Mobile Menu', 'Mobile Menu Toggle');
      }}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-noble-navy px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Noble Surveys</span>
              <img
                className="h-12 w-auto"
                src={`${process.env.PUBLIC_URL}/images/NobleSurveys 6 800x200.png`}
                alt="Noble Surveys"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-noble-gray"
              onClick={() => {
                setMobileMenuOpen(false);
                trackEvent('Navigation', 'Close Mobile Menu', 'Mobile Menu Toggle');
              }}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-noble-white hover:bg-noble-white/10"
                    onClick={() => {
                      handleNavClick(item.name, true);
                      setMobileMenuOpen(false);
                    }}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <Link
                  to="/contact"
                  className="bg-noble-white text-noble-navy px-6 py-3 rounded-lg font-semibold hover:bg-noble-blue hover:text-noble-white transition-all duration-300 ease-in-out w-full text-center"
                  onClick={() => {
                    handleNavClick('Get Started', true);
                    setMobileMenuOpen(false);
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
