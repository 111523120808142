import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbContainer = styled.nav`
  padding: 1rem 2rem;
  background: #f8f9fa;
  margin-bottom: 1rem;
`;

const BreadcrumbList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
  
  &:not(:last-child)::after {
    content: '/';
    margin: 0 0.5rem;
    color: #6c757d;
  }

  a {
    color: #0d6efd;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &:last-child a {
    color: #6c757d;
    pointer-events: none;
  }
`;

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Schema.org breadcrumb list markup
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: pathnames.map((value, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `${window.location.origin}/${pathnames.slice(0, index + 1).join('/')}`,
        name: value.charAt(0).toUpperCase() + value.slice(1).replace(/-/g, ' '),
      },
    })),
  };

  return (
    <BreadcrumbContainer aria-label="breadcrumb">
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
      <BreadcrumbList>
        <BreadcrumbItem>
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const name = value.charAt(0).toUpperCase() + value.slice(1).replace(/-/g, ' ');
          
          return (
            <BreadcrumbItem key={to}>
              <Link to={to}>{name}</Link>
            </BreadcrumbItem>
          );
        })}
      </BreadcrumbList>
    </BreadcrumbContainer>
  );
};

export default Breadcrumbs;
